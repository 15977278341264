import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  CircularProgress,
  Alert,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const VetClinicRecordEdit = () => {
  const { record_id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    record_id: '',
    payment_type_id: '',
    payment_type: '',
    record_date: '',
    horse_id: '',
    outside_horse_id: '',
    inside_horse_id: '',
    rfid_chip_number: '',
    horse_name: '',
    total_amount: '',
    work_place: '',
    file_upload: '',
    medical_history: '',
    diagnosis: '',
    treatment: '',
    notes: '',
    payment_type_name: '',
    approval: 0,
    client_id: '',
    owner_id: '',
    user_id: '',
    medications: [],
    procedures: [],
    file_uploads: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [medications, setMedications] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]); // حالة لتخزين قائمة أنواع الدفع المتاحة
  const [errors] = useState({});

  const calculateTotalAmount = useCallback(() => {
    const proceduresTotal = formData.procedures.reduce((sum, proc) => {
      return sum + (proc.price || 0);
    }, 0);

    const medicationsTotal = formData.medications.reduce(
      (sum, med) => sum + (med.price || 0),
      0
    );

    setFormData((prevState) => ({
      ...prevState,
      total_amount: proceduresTotal + medicationsTotal,
    }));
  }, [formData.procedures, formData.medications]);

  useEffect(() => {
    calculateTotalAmount();
  }, [formData.procedures, formData.medications, calculateTotalAmount]);

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const uploadPromises = files.map((file) => {
      const formData = new FormData();
      formData.append('file', file);

      return axios.post(`${apiUrl}/vetclinic-records/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    });

    try {
      const responses = await Promise.all(uploadPromises);
      const uploadedFiles = responses.map((response) => response.data.filePath);

      setFormData((prevState) => ({
        ...prevState,
        file_uploads: [...prevState.file_uploads, ...uploadedFiles],
      }));
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  useEffect(() => {
    console.log('Form Data:', formData); // Logging the form data
  }, [formData]);
  const fetchRecordDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/vetclinic-records/${record_id}`,
        {
          headers: {
            Authorization: 'your_jwt_secret_key',
          },
        }
      );

      const recordData = response.data;
      if (recordData.record_date) {
        setFormData((prevData) => ({
          ...prevData,
          record_id: recordData.record_id || '',
          payment_type_id: recordData.payment_type_id || '',
          payment_type: recordData.payment_type || '',
          record_date: recordData.record_date.split('.')[0], // إزالة الجزء الزائد من التاريخ
          horse_id: recordData.horse_id || '',
          outside_horse_id: recordData.outside_horse_id || '',
          inside_horse_id: recordData.inside_horse_id || '',
          rfid_chip_number: recordData.rfid_chip_number || '',
          horse_name: recordData.horse_name || '',
          total_amount: recordData.total_amount || '',
          work_place: recordData.work_place || '',
          file_upload: recordData.file_upload || '',
          medical_history: recordData.medical_history || '',
          diagnosis: recordData.diagnosis || '',
          treatment: recordData.treatment || '',
          notes: recordData.notes || '',
          payment_type_name: recordData.payment_type_name || '',
          approval: recordData.approval || 0,
          client_id: recordData.client_id || '',
          owner_id: recordData.owner_id || '',
          quantity: recordData.quantity || '',
          user_id: recordData.user_id || '',
        }));
      }

      const medicationsResponse = await axios.get(
        `${apiUrl}/medications-records/${record_id}`
      );
      setFormData((prevData) => ({
        ...prevData,
        medications: medicationsResponse.data,
      }));

      const proceduresResponse = await axios.get(
        `${apiUrl}/procedures-records/${record_id}`
      );
      setFormData((prevData) => ({
        ...prevData,
        procedures: proceduresResponse.data,
      }));

      setLoading(false);
    } catch (err) {
      console.error('Error fetching record details:', err);
      setError('Error fetching record details.');
      setLoading(false);
    }
  }, [record_id]);

  useEffect(() => {
    fetchRecordDetails();
    fetchDropdownData();
  }, [record_id, fetchRecordDetails]);

  const fetchDropdownData = async () => {
    try {
      const [medicationsResponse, proceduresResponse, paymentTypesResponse] =
        await Promise.all([
          axios.get(`${apiUrl}/medications`),
          axios.get(`${apiUrl}/procedures`),
          axios.get(`${apiUrl}/payment-types`), // جلب قائمة أنواع الدفع
        ]);

      setMedications(medicationsResponse.data);
      setProcedures(proceduresResponse.data);
      setPaymentTypes(paymentTypesResponse.data); // تعيين قائمة أنواع الدفع
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
      setError('Error fetching dropdown data.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleMedicationChange = (index, field, value) => {
    setFormData((prevState) => {
      const updatedMedications = prevState.medications.map((med, i) => {
        if (i === index) {
          const updatedMed = { ...med, [field]: value };

          if (field === 'medication_id') {
            const selectedMed = medications.find(
              (m) => m.medication_id === parseInt(value)
            );
            if (selectedMed) {
              updatedMed.dosage = selectedMed.default_dosage || '';
              updatedMed.quantity = selectedMed.default_quantity || 1;
              updatedMed.unit = selectedMed.default_unit || '';
              updatedMed.price = selectedMed.price || 0;
            }
          }

          return updatedMed;
        }
        return med;
      });

      return { ...prevState, medications: updatedMedications };
    });
  };

  // Add price when changing procedures
  const handleProcedureChange = (procedure_id) => {
    setFormData((prevState) => {
      const proc = procedures.find((p) => p.procedure_id === procedure_id);
      if (!proc) return prevState;

      const updatedProcedures = prevState.procedures.some(
        (p) => p.procedure_id === procedure_id
      )
        ? prevState.procedures.filter((p) => p.procedure_id !== procedure_id)
        : [...prevState.procedures, { ...proc, price: proc.price || 0 }];

      return { ...prevState, procedures: updatedProcedures };
    });
  };

  const addMedication = () => {
    setFormData((prevData) => ({
      ...prevData,
      medications: [
        ...prevData.medications,
        { medication_id: '', dosage: '', quantity: '', unit: '', price: 0 },
      ],
    }));
  };

  const removeMedication = (index) => {
    const newMedications = formData.medications.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, medications: newMedications }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*
    if (!formData.payment_type_id) {
      setError('Payment type is required.');
      return;
    }*/

    const sanitizedFormData = {
      ...formData,
      horse_id: formData.horse_id || null,
      file_upload: formData.file_upload || null,
      payment_type_id: formData.payment_type_id,
      client_id: formData.client_id || null,
      owner_id: formData.owner_id || null,
      inside_horse_id: formData.inside_horse_id || null,
      outside_horse_id: formData.outside_horse_id || null,
      notes: formData.notes || null,
      medical_history: formData.medical_history || null,
      diagnosis: formData.diagnosis || null,
      treatment: formData.treatment || null,
    };

    try {
      await axios.put(
        `${apiUrl}/vetclinic-records-edit/${record_id}`,
        sanitizedFormData
      );
      navigate(`/record-details/${record_id}`);
    } catch (err) {
      console.error('Failed to update record:', err.message);
      setError('Error updating record.');
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Edit Vet Clinic Record
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="record_date"
                label="Record Date"
                type="datetime-local"
                fullWidth
                value={formData.record_date}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="work_place"
                label="Work Place"
                fullWidth
                value={formData.work_place}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                margin="normal"
                error={!!errors.payment_type_id}
                required // إضافة هذا السطر لجعل الحقل مطلوبًا
              >
                <InputLabel>Payment Type</InputLabel>
                <Select
                  name="payment_type_id"
                  value={formData.payment_type_id}
                  onChange={handleChange}
                >
                  {paymentTypes.map((type) => (
                    <MenuItem
                      key={type.payment_type_id}
                      value={type.payment_type_id}
                    >
                      {type.payment_type_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.payment_type_id && (
                  <Typography color="error">
                    {errors.payment_type_id}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                name="rfid_chip_number"
                label="RFID Chip Number"
                fullWidth
                value={formData.rfid_chip_number}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="horse_name"
                label="Horse Name"
                fullWidth
                value={formData.horse_name}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="user_id"
                label="User ID"
                type="number"
                fullWidth
                value={formData.user_id}
                onChange={handleChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="total_amount"
                label="Total Amount"
                type="number"
                fullWidth
                value={formData.total_amount}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" margin="normal">
                <Typography>Procedures</Typography>
                <Grid container spacing={2}>
                  {procedures.map((procedure) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={procedure.procedure_id}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={procedure.procedure_id}
                            checked={formData.procedures.some(
                              (proc) =>
                                proc.procedure_id === procedure.procedure_id
                            )}
                            onChange={() =>
                              handleProcedureChange(procedure.procedure_id)
                            }
                          />
                        }
                        label={procedure.procedure_name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="medical_history"
                label="Medical History"
                multiline
                rows={4}
                fullWidth
                value={formData.medical_history}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="diagnosis"
                label="Diagnosis"
                multiline
                rows={4}
                fullWidth
                value={formData.diagnosis}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="treatment"
                label="Treatment"
                multiline
                rows={4}
                fullWidth
                value={formData.treatment}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="notes"
                label="Notes"
                multiline
                rows={4}
                fullWidth
                value={formData.notes || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                margin="dense"
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <Typography>Medications</Typography>
                {formData.medications.map((medication, index) => (
                  <div key={index} style={{ marginBottom: '10px' }}>
                    <Select
                      name="medication_id"
                      value={medication.medication_id || ''}
                      onChange={(e) =>
                        handleMedicationChange(
                          index,
                          'medication_id',
                          e.target.value
                        )
                      }
                      fullWidth
                    >
                      {medications.map((med) => (
                        <MenuItem
                          key={med.medication_id}
                          value={med.medication_id}
                        >
                          {med.medication_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <TextField
                      label="Dosage"
                      name="dosage"
                      value={medication.dosage}
                      onChange={(e) =>
                        handleMedicationChange(index, 'dosage', e.target.value)
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Quantity"
                      type="number"
                      name="quantity"
                      value={medication.quantity}
                      onChange={(e) =>
                        handleMedicationChange(
                          index,
                          'quantity',
                          e.target.value
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Unit"
                      name="unit"
                      value={medication.unit}
                      onChange={(e) =>
                        handleMedicationChange(index, 'unit', e.target.value)
                      }
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => removeMedication(index)}
                    >
                      Remove Medication
                    </Button>
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addMedication}
                >
                  Add Medication
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Upload Files</Typography>
              <input type="file" multiple onChange={handleFileUpload} />
            </Grid>

            {formData.file_uploads.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6">Uploaded Files</Typography>
                <ul>
                  {formData.file_uploads.map((file, index) => (
                    <li key={index}>{file}</li>
                  ))}
                </ul>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginRight: '10px' }}
              >
                Save Changes
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default VetClinicRecordEdit;
